

















    import '@sverigesradio/stil/components/ButtonImageText.lit';
    import SectionHeader from '@/components/shared/SectionHeader.vue';
    import Vue from 'vue';
    import { CategoryButtonClickedEvent } from '@/common/custom-events/category-button-clicked-event';
    import { ProgramCategory, programCategoryApi } from '@/api/program-category-api';

    export default Vue.extend({
        components: {
            SectionHeader
        },
        async created() {
            const categoriesResponse = await programCategoryApi.getProgramCategories();
            this.programCategories.title = categoriesResponse.data.title;
            this.programCategories.items = categoriesResponse.data.items;
        },
        data() {
            return {
                programCategories: {
                    title: '',
                    items: [] as ProgramCategory[]
                }
            };
        },
        methods: {
            trackCategoryButton(category: ProgramCategory) {
                window.dispatchEvent(new CategoryButtonClickedEvent(category.url, category.name));
            }
        }
    });
