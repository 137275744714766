import httpClient from '@/common/http-client';
import linkEvaluator from '@/common/link-evaluator';
const parser = new DOMParser();

export default {
    async getHtml(url: string): Promise<SpaNavigationResponse> {
        const response = await httpClient.get<string>(url);

        return {
            html: parser.parseFromString(response.data, 'text/html'),
            status: response.status,
            redirectedToUrl: getRedirectUrl(url, response.request?.responseURL),
            xPoweredByNextJs: response.request?.getResponseHeader('x-powered-by')?.includes('Next.js') ?? false
        };
    }
};

export interface SpaNavigationResponse {
    html: Document;
    redirectedToUrl?: string;
    status: number;
    xPoweredByNextJs: boolean;
}

function getRedirectUrl(url: string, responseURL: string | undefined): string | undefined {
    if (!url || !responseURL || linkEvaluator.isSameRelativeUrl(url, responseURL)) {
        return undefined;
    }

    return getRelativeUrl(responseURL);
}

function getRelativeUrl(strUrl: string): string {
    const url = new URL(strUrl, window.location.origin);
    return url.pathname + url.search;
}
