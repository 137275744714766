




















































    import { Vue, Component } from 'vue-property-decorator';
    import UiButton from '@/styleguide/elements/UiButton.vue';
    import SvgImage from '../shared/SvgImage.vue';
    import deviceDetection from '@/common/device-detection';
    import DeepLinker from '../../common/deep-linker';

    @Component({
        components: {
            UiButton,
            SvgImage
        }
    })
    export default class ListenLater extends Vue {
        private isMobile: boolean = false;
        private deepLinker?: DeepLinker;

        private readonly installAppleAppStore: string = 'https://apps.apple.com/se/app/sveriges-radio-play/id300548244';
        private readonly installAndroidPlayStore: string =
            'https://play.google.com/store/apps/details?id=se.sr.android#?t=w251bgwsmswxldixmiwic2uuc3iuyw5kcm9pzcjd';

        private androidUrl(): string {
            const url = 'play';
            const fallbackUrl = encodeURIComponent('sesrplay://play');

            return `intent://${url}#Intent;package=se.sr.android;scheme=sesrplay;S.browser_fallback_url=${fallbackUrl};end`;
        }

        private iosUrl(): string {
            return 'sesrplay://play';
        }

        private downloadButtonClicked(): void {
            if (!this.isMobile) {
                return;
            }

            const deepUrl = deviceDetection.isIos ? this.iosUrl() : this.androidUrl();
            this.deepLinker?.openURL(deepUrl);
        }

        private mounted(): void {
            this.isMobile = deviceDetection.isSmartphoneOrTablet;

            if (!this.isMobile) {
                return;
            }

            this.deepLinker = new DeepLinker({
                onIgnored: (): void => {
                    window.location.replace(deviceDetection.isIos ? this.installAppleAppStore : this.installAndroidPlayStore);
                }
            });
        }
    }
