// polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './polyfills';
import './ie-polyfills';

import applicationInit from './application-init';
import domHookup from './dom-hookup';
import { domReady } from './dom-ready';
import { registerErrorLoggingEventListeners } from './logging/global-error-logging';
import { clickEventListener } from '@/spa-navigation/click-event-listener';
import { popstateEventListener } from '@/spa-navigation/popstate-event-listener';
import { gotopageEventListener } from '@/spa-navigation/gotopage-event-listener';

// register logging before other code
registerErrorLoggingEventListeners();

// general site css
import '../style/main.scss';
import { GoToPageEvent } from './common/custom-events/go-to-page-event';
import { consentService } from './common/consent-service';
import spaPageTracker from './spa-navigation/spa-page-tracker';
import { initStatisticService } from './tracking/statistic-service';
import { cookieConsentUpdatedTracker } from './common/cookie-consent-updated-tracker';
import { pageMetadataService } from './common/page-metadata-service';
import { PageChangedEvent } from './common/custom-events/page-changed-event';
import '@sverigesradio/stil/dist/style.css';

domReady(() => {
    initStatistics();
    initSpaNavigation();
    applicationInit.init();
    domHookup.init();
    document.body.classList.add('js-ready');
    window.dispatchEvent(new PageChangedEvent(document));
    spaPageTracker.track();
});

function initStatistics(): void {
    cookieConsentUpdatedTracker.init();
    if (consentService.isStatisticsAllowed()) {
        initStatisticService();
    }
}

function initSpaNavigation(): void {
    addEventListener('click', clickEventListener);
    addEventListener('popstate', popstateEventListener);
    addEventListener(GoToPageEvent.EventName, gotopageEventListener);
    pageMetadataService.init();
}
