






    import Vue, { PropType } from 'vue';
    import '@sverigesradio/stil/components/NavTopBar.lit.js';
    import '@sverigesradio/stil/components/NavItem.lit.js';

    interface NavItem {
        url: string,
        title: string
    }

    export default Vue.extend({
        props: {
            navItems: {
                type: Array as PropType<NavItem[]>,
                required: true
            }
        },
        methods: {
            isActive(url: string): boolean {
                return location.pathname === url
            }
        }
    });
