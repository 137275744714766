import Vue, { Component } from 'vue';
import Vuex from 'vuex';

// store
import RootStore from '@/store/root-store';

// global
import SiteApp from '@/components/site-app.vue';
import MainMenu from '@/components/main-menu/MainMenu.vue';

// disposables
import LocalModule from '@/components/local-module/local-module.vue';
import LocalModuleOnPage from '@/components/local-module/local-module-on-page.vue';
import LatestWeatherReport from '@/components/latest-weather-report/latest-weather-report.vue';
import ListenLater from '@/components/listen-later/ListenLater.vue';
import MinSidaMessage from '@/components/listen-later/MinSidaMessage.vue';
import SearchPage from '@/components/search/SearchPage.vue';
import SlideshowButton from '@/components/slideshow/SlideshowButton.vue';
import Curated from '@/components/curated-list/Curated.vue';
import CuratedCarousel from '@/components/curated-carousel/CuratedCarousel.vue';
import PublicationRelations from '@/components/publication-relations/PublicationRelations.vue';
import PublicationMainGroup from '@/components/publication-relations/PublicationMainGroup.vue';
import EpisodeRecommendations from '@/components/recommendations/EpisodeRecommendations.vue';
import AllChannels from '@/components/all-channels/AllChannels.vue';
import ExtraBroadcasts from '@/components/extra-broadcasts/ExtraBroadcasts.vue';
import LatestEpisode from '@/components/latest-episode/LatestEpisode.vue';
import ExtraBroadcastDetails from '@/components/extra-broadcasts/ExtraBroadcastDetails.vue';
import SingleChannelModule from '@/components/channel-module/SingleChannelModule.vue';
import ChannelCarousel from '@/components/channels/ChannelCarousel.vue';
import LatestNewsEpisodes from '@/components/priority-program-episodes/LatestNewsEpisodes.vue';
import VideoBlob from '@/components/shared/VideoBlob.vue';
import HtmlBlob from '@/components/shared/HtmlBlob.vue';
import AppBanner from '@/components/shared/AppBanner.vue';
import CompactSecondaryMenu from '@/components/secondary-menu/CompactSecondaryMenu.vue';
import ProgramSecondaryMenu from '@/components/secondary-menu/ProgramSecondaryMenu.vue';
import CollaborationIcons from '@/components/shared/CollaborationIcons.vue';
import StartPageSecondaryMenu from '@/components/secondary-menu/StartPageSecondaryMenu.vue';
import NewsPlaylistCarousel from '@/components/news-playlist/NewsPlaylistCarousel.vue';
import StoryCollection from '@/components/story-collection/StoryCollection.vue';
import StoryAudioButton from '@/components/story-collection/StoryAudioButton.vue';
import { getPropertiesFromAttributes } from '@/common/dom-helpers';
import type { VNode } from 'vue/types/umd';
import { vueErrorHandler } from '@/logging/global-error-logging';
import FooterLinks from '@/components/footer/FooterLinks.vue';
import CookiePopover from '@/components/cookie-consent/CookiePopover.vue';
import ProgramPage from '@/components/poddar-program/poddar-program-page/ProgramPage.vue';
import ProgramCategoryPage from '@/components/poddar-program/category-page/ProgramCategoryPage.vue';
import PageHeader from '@/components/poddar-program/PageHeader.vue';

Vue.use(Vuex);
const store = new Vuex.Store(RootStore);

Vue.config.errorHandler = vueErrorHandler;

let vueComponentCache: Vue[] = [];

export default {
    init(): void {
        initGlobalComponents();
        initComponents();
    },
    initFromSinglePage(): void {
        initComponents();
    },
    destroy(): void {
        vueComponentCache.forEach(component => {
            component.$destroy();
        });
        vueComponentCache = [];
    }
};

function initGlobalComponents(): void {
    const components: Record<string, Component> = {
        'site-app': SiteApp,
        'main-menu': MainMenu,
        'app-banner': AppBanner
    };
    initVueComponents(components, false);
}

function initComponents(): void {
    const components: Record<string, Component> = {
        'search-page': SearchPage,
        'listen-later': ListenLater,
        'min-sida-message': MinSidaMessage,
        'local-module': LocalModule,
        'local-module-on-page': LocalModuleOnPage,
        'latest-weather-report': LatestWeatherReport,
        'slideshow-button': SlideshowButton,
        curated: Curated,
        'curated-carousel': CuratedCarousel,
        'publication-relations': PublicationRelations,
        'publication-main-group': PublicationMainGroup,
        'episode-recommendations': EpisodeRecommendations,
        'all-channels': AllChannels,
        'extra-broadcasts': ExtraBroadcasts,
        'latest-episode': LatestEpisode,
        'extra-broadcast-details': ExtraBroadcastDetails,
        'single-channel-module': SingleChannelModule,
        'channel-carousel': ChannelCarousel,
        'latest-news-episodes': LatestNewsEpisodes,
        'video-blob': VideoBlob,
        'html-blob': HtmlBlob,
        'compact-secondary-menu': CompactSecondaryMenu,
        'program-secondary-menu': ProgramSecondaryMenu,
        'start-page-secondary-menu': StartPageSecondaryMenu,
        'collaboration-icons': CollaborationIcons,
        'news-playlist-carousel': NewsPlaylistCarousel,
        'story-collection': StoryCollection,
        'footer-links': FooterLinks,
        'cookie-popover': CookiePopover,
        'story-audio-button': StoryAudioButton,
        'program-page': ProgramPage,
        'program-category-page': ProgramCategoryPage,
        'page-header': PageHeader
    };
    initVueComponents(components, true);
}

function initVueComponents(components: Record<string, Component>, putInCache: boolean): void {
    const attributeName = 'data-vue-component';
    const elements = document.querySelectorAll(`[${attributeName}]`);

    let i: number;
    for (i = 0; i < elements.length; i++) {
        const componentName = elements[i].getAttribute(attributeName);

        if (componentName && components[componentName]) {
            elements[i].removeAttribute(attributeName);
            initElement(elements[i], components[componentName], putInCache);
        }
    }
}

function initElement(element: Element, component: Component, putInCache: boolean): void {
    const vm = new Vue({
        el: element,
        store,
        render: (createElement): VNode =>
            createElement(component, {
                props: getPropertiesFromAttributes(element)
            })
    });

    if (putInCache) {
        vueComponentCache.push(vm);
    }
}
