








































    import { Mixins, Component, Prop } from 'vue-property-decorator';

    import SvgImage from '@/components/shared/SvgImage.vue';
    import Thumbnail from '@/components/shared/Thumbnail.vue';
    import Flex from '@/components/shared/Flex.vue';
    import AudioButton from '@/components/shared/AudioButton.vue';
    import UiOverline from '@/styleguide/elements/UiOverline.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import HyphenationPoint from '@/components/shared/ascii/HyphenationPoint.vue';
    import { ListenLaterResponse } from '@/api/listen-later-api';
    import MetadataMixin from '../mixins/MetadataMixin';
    import anime from 'animejs';
    import { GoToPageEvent } from '@/common/custom-events/go-to-page-event';

    @Component({
        components: {
            SvgImage,
            Thumbnail,
            AudioButton,
            UiHeading,
            UiCaption,
            UiOverline,
            HyphenationPoint,
            Flex
        }
    })
    export default class ListenLaterItem extends Mixins(MetadataMixin) {
        @Prop(Object) item!: ListenLaterResponse;
        @Prop(Boolean) editMode?: boolean;

        removeItem(): void {
            this.$store.dispatch('listenLater/removeItem', this.item);
            this.$emit('remove');
        }

        goToItemUrl(): void {
            window.dispatchEvent(new GoToPageEvent(this.item.url));
        }

        onClick(): void {
            this.$emit('play');
        }

        controlsEnter(el: HTMLElement): void {
            anime({
                targets: el,
                opacity: [0, 1],
                translateX: [100, 0],
                duration: 300,
                easing: 'easeOutCubic'
            });
        }

        controlsLeave(el: HTMLElement, doneFunc: (anim: anime.AnimeInstance) => void): void {
            anime({
                targets: el,
                opacity: [1, 0],
                translateX: [0, 100],
                duration: 250,
                easing: 'easeOutCubic',
                complete: doneFunc
            });
        }

        get removeAriaLabel(): string {
            return `Ta bort ${this.item.title}`;
        }
    }
