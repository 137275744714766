



















































































    import { Vue, Component } from 'vue-property-decorator';
    import Modal from '@/components/shared/Modal.vue';
    import { CookieConsentSource, ShowCookieConsentEvent } from '@/common/custom-events/show-cookie-consent-event';
    import { SpaNavigationStartedEvent } from '@/common/custom-events/spa-navigation-started-event';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiLinkInternal from '@/styleguide/elements/UiLinkInternal.vue';
    import UiButton from '@/styleguide/elements/UiButton.vue';
    import UiBodyText from '@/styleguide/elements/UiBodyText.vue';
    import { consentService } from '@/common/consent-service';
    import WarningSign from '@/components/shared/ascii/WarningSign.vue';
    import SvgImage from '@/components/shared/SvgImage.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import Checkbox from '@/components/shared/forms/Checkbox.vue';
    import { CookieConsentUpdatedEvent } from '@/common/custom-events/cookie-consent-updated-event';
    import { initStatisticService } from '@/tracking/statistic-service';
    import { CookieSettings } from '@/common/storage';
    import { cookieConsentUpdatedTracker } from '@/common/cookie-consent-updated-tracker';

    @Component({
        components: {
            Modal,
            UiHeading,
            UiLinkInternal,
            UiButton,
            UiBodyText,
            WarningSign,
            SvgImage,
            UiCaption,
            Checkbox
        }
    })
    export default class CookieConsent extends Vue {
        private isOpen: boolean = false;
        private dialogOrigin: CookieConsentSource = 'other';
        private settingsAllow!: boolean;
        private statisticAllow!: boolean;
        private thirdPartyAllowed!: boolean;
        private consentSaved = false;

        get extraText(): string {
            const commonText = 'behöver du godkänna att sverigesradio.se får spara de inställningar du gör.';

            switch (this.dialogOrigin) {
                case 'add-to-my-list':
                    return `För att lägga till innehåll i min sida ${commonText}`;
                case 'select-additional-channel':
                    return `För att kunna välja favoritkanal ${commonText}`;
                case 'select-local-channel':
                    return `För att kunna välja P4-kanal ${commonText}`;
                default:
                    return '';
            }
        }

        private acceptAll(): void {
            this.settingsAllow = true;
            this.statisticAllow = true;
            this.thirdPartyAllowed = true;
            this.acceptSelected();
            this.close();
        }

        private acceptMandatory(): void {
            this.settingsAllow = false;
            this.statisticAllow = false;
            this.thirdPartyAllowed = false;
            this.acceptSelected();
            this.close();
        }

        private acceptSelected(): void {
            consentService.accept({
                settingsAllowed: this.settingsAllow,
                statisticsAllowed: this.statisticAllow,
                thirdPartyAllowed: this.thirdPartyAllowed
            });

            if (this.statisticAllow) {
                initStatisticService();
                cookieConsentUpdatedTracker.sendPageTrack();
            }

            this.close();
        }

        private open(event: ShowCookieConsentEvent): void {
            this.isOpen = true;
            this.dialogOrigin = event.detail.source;
        }

        private close(): void {
            this.isOpen = false;
        }

        private created(): void {
            window.addEventListener(ShowCookieConsentEvent.EventName, this.open);
            window.addEventListener(SpaNavigationStartedEvent.EventName, this.close);
        }

        private mounted(): void {
            const cookieSettings = consentService.getCookieSettings();
            this.updateSettings(cookieSettings);

            window.addEventListener(CookieConsentUpdatedEvent.EventName, this.onConsentSavedUpdated);
        }

        private destroyed(): void {
            window.removeEventListener(ShowCookieConsentEvent.EventName, this.open);
            window.removeEventListener(SpaNavigationStartedEvent.EventName, this.close);
            window.removeEventListener(CookieConsentUpdatedEvent.EventName, this.onConsentSavedUpdated);
        }

        private updateSettings(cookieSettings: CookieSettings): void {
            this.settingsAllow = cookieSettings.settingsAllowed;
            this.statisticAllow = cookieSettings.statisticsAllowed;
            this.thirdPartyAllowed = cookieSettings.thirdPartyAllowed;
        }

        onConsentSavedUpdated(event: CookieConsentUpdatedEvent): void {
            this.updateSettings(event.detail.currentCookieSettings);
            this.consentSaved = event.detail.currentCookieSettings.consentSaved;
        }
    }
