
































    import { Mixins, Component } from 'vue-property-decorator';
    import ListenLaterItem from './ListenLaterItem.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiBodyText from '@/styleguide/elements/UiBodyText.vue';
    import Flex from '@/components/shared/Flex.vue';
    import { AudioType, AudioKey } from '@/components/models';
    import { ListenLaterAudioItem } from '@/store/listen-later-store';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import MetadataMixin from '@/components/mixins/MetadataMixin';
    import AriaLabelsMixin from '@/components/mixins/AriaLabelsMixin';
    import ListenLaterMixin from '@/components/mixins/ListenLaterMixin';
    import { consentService } from '@/common/consent-service';
    import ListenLaterDisabled from '@/components/shared/ListenLaterDisabled.vue';
    import { CookieConsentUpdatedEvent } from '@/common/custom-events/cookie-consent-updated-event';

    @Component({
        components: {
            ListenLaterItem,
            draggable: () => import(/* webpackChunkName: "vuedraggable" */ 'vuedraggable'),
            UiHeading,
            UiBodyText,
            Flex,
            ListenLaterDisabled
        }
    })
    export default class ListenLater extends Mixins(PlaybackMixin, MetadataMixin, AriaLabelsMixin, ListenLaterMixin) {
        emptyListMessage = 'Lägg till avsnitt och klipp i din lista som du vill lyssna på senare.';
        editMode: boolean = false;
        private settingsAllowed: boolean = false;

        get items(): ListenLaterAudioItem[] {
            return this.$store.getters['listenLater/items'];
        }

        get audioItems(): AudioKey[] {
            return this.items.map(item => {
                return {
                    id: item.audio.audioId,
                    type: item.audio.audioType as AudioType
                };
            });
        }

        get itemsWithAudio(): ListenLaterAudioItem[] {
            return this.$store.getters['listenLater/itemsWithAudio'];
        }

        get sortableItems(): ListenLaterAudioItem[] {
            return this.items;
        }

        set sortableItems(value: ListenLaterAudioItem[]) {
            this.$store.dispatch('listenLater/updateList', value);
            if (this.isListenLaterList) {
                this.rearrange(this.itemsToPlay as AudioKey[]);
            }
        }

        get ready(): boolean {
            return this.$store.getters['listenLater/ready'];
        }

        get showList(): boolean {
            return this.isEnabled && this.items.length > 0 && this.ready;
        }

        get showEmptyMessage(): boolean {
            return this.items.length === 0 && this.ready;
        }

        get editButtonLabel(): string {
            return this.editMode ? 'Klar' : 'Ändra';
        }

        get itemsToPlay(): object[] {
            if (!this.itemsWithAudio.length) {
                return [];
            }

            return this.itemsWithAudio.map(item => {
                return { id: item.audio.audioId, type: item.audio.audioType };
            });
        }

        get isEnabled(): boolean {
            return this.settingsAllowed;
        }

        mounted(): void {
            this.settingsAllowed = consentService.isSettingsAllowed();
            window.addEventListener(CookieConsentUpdatedEvent.EventName, this.onCookieConsentUpdated);
        }

        destroyed(): void {
            window.removeEventListener(CookieConsentUpdatedEvent.EventName, this.onCookieConsentUpdated);
        }

        onCookieConsentUpdated(event: CookieConsentUpdatedEvent): void {
            this.settingsAllowed = event.detail.currentCookieSettings.settingsAllowed;
        }

        toggleEditMode(): void {
            this.editMode = !this.editMode;
        }

        onRemove(): void {
            if (this.isListenLaterList) {
                this.rearrange(this.itemsToPlay as AudioKey[]);
            }
        }

        onPlay(index: number): void {
            this.playQueue(this.audioItems, index, 'Min sida', true);
        }

        created(): void {
            this.$store.dispatch('listenLater/load');
        }
    }
