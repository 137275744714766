












    import Vue from 'vue';
    import '@sverigesradio/stil/components/Image.lit.js';
    import '@sverigesradio/stil/components/Text.lit.js';
    import '@sverigesradio/stil/components/Grid.lit.js';

    export default Vue.extend({
        props: {
            title: {
                type: String,
                required: true
            },
            description: {
                type: String,
                required: true
            },
            url: {
                type: String,
                required: true
            },
            imgSrc: {
                type: String,
                required: false
            }
        }
    });
