import { customEventInit } from './custom-events-common';

export type CookieConsentSource = 'add-to-my-list' | 'select-local-channel' | 'select-additional-channel' | 'other';

type ShowCookieConsentEventPayload = { source: CookieConsentSource };

export class ShowCookieConsentEvent extends CustomEvent<ShowCookieConsentEventPayload> {
    public static EventName = 'show-cookie-consent' as const;

    constructor(payload?: CookieConsentSource) {
        super(ShowCookieConsentEvent.EventName, customEventInit({ source: payload ?? 'other' }));
    }
}

declare global {
    interface WindowEventMap {
        [ShowCookieConsentEvent.EventName]: ShowCookieConsentEvent;
    }
}
