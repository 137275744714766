import userState from '@/common/user-state.js';
import IDomScript from '@/modules/i-dom-script';
import { consentService } from '@/common/consent-service';
import { ShowCookieConsentEvent } from '@/common/custom-events/show-cookie-consent-event';
import { TrackingMetadata } from '@/tracking/track-types';
import { getMetadata } from '@/common/tracking-metadata';
import { AddToListClickedEvent } from '@/common/custom-events/add-to-list-clicked-event';
import { RemoveFromListClickedEvent } from '@/common/custom-events/remove-from-list-clicked-event';

export default class ListenLater implements IDomScript {
    private el: HTMLElement;
    private audioId: string | null;
    private audioType: string | null;
    private addAriaLabel: string;
    private removeAriaLabel: string;
    private readonly metadata: TrackingMetadata = {};
    private onClick: EventListener;

    public constructor(el: HTMLElement) {
        this.el = el;
        this.audioId = this.el.getAttribute('data-audio-id');
        this.audioType = this.el.getAttribute('data-audio-type');
        this.addAriaLabel = this.el.getAttribute('data-add-aria-label') || '';
        this.removeAriaLabel = this.el.getAttribute('data-remove-aria-label') || '';
        this.metadata = getMetadata(this.el);

        this.onClick = (): void => {
            if (!consentService.isSettingsAllowed()) {
                window.dispatchEvent(new ShowCookieConsentEvent('add-to-my-list'));
                return;
            }

            userState.listenLater.toggle(this.audioId, this.audioType, (existsInList: boolean) => {
                this.toggleState(existsInList);
                this.gaTracking(existsInList);
            });
        };
    }

    private toggleState(existsInList: boolean): void {
        if (existsInList) {
            this.el.classList.add('listen-later--added');
            this.el.setAttribute('aria-label', this.removeAriaLabel);
        } else {
            this.el.classList.remove('listen-later--added');
            this.el.setAttribute('aria-label', this.addAriaLabel);
        }
    }

    private gaTracking(existsInList: boolean): void {
        if (existsInList) {
            window.dispatchEvent(new AddToListClickedEvent(this.metadata));
        } else {
            window.dispatchEvent(new RemoveFromListClickedEvent(this.metadata));
        }
    }

    public execute(): void {
        userState.listenLater.exists(this.audioId, this.audioType, (existsInList: boolean) => {
            this.toggleState(existsInList);
        });
        this.el.addEventListener('click', this.onClick);
    }

    public dispose(): void {
        this.el.removeEventListener('click', this.onClick);
    }
}
