










    import UiButton from '@/styleguide/elements/UiButton.vue';
    import { Vue, Component } from 'vue-property-decorator';
    import { ShowCookieConsentEvent } from '@/common/custom-events/show-cookie-consent-event';

    @Component({
        components: {
            UiButton
        }
    })
    export default class ListenLaterDisabled extends Vue {
        showConsentDialog(): void {
            window.dispatchEvent(new ShowCookieConsentEvent());
        }
    }
