import IDomScript from '@/modules/i-dom-script';
import { AudioKey, AudioType } from '@/components/models';

export default class PlayOnClick implements IDomScript {
    private el: HTMLElement | null;

    public constructor(el: HTMLElement) {
        this.el = el;
    }

    private play(): void {
        if (!this.el) {
            return;
        }

        const audioType = this.el.getAttribute('data-audio-type') as AudioType;
        const id = Number(this.el.getAttribute('data-audio-id'));
        const startTime = Number(this.el.getAttribute('data-starttime'));

        if (!audioType || !id) {
            return;
        }

        const publicationId = Number(this.el.getAttribute('data-publication-id')) || undefined;

        const audio: AudioKey = {
            id,
            type: audioType,
            publicationId
        };

        const playlist = this.createPlaylist();
        const playlistIndex = this.getQueueindex(playlist, Number(audio.id), audio.type);
        const isPlaying = this.el.getAttribute('is-playing');

        if (isPlaying) {
            window.sr.player.pause();
        } else if (playlistIndex > -1 && playlist?.audios) {
            window.sr.player.playQueue(playlist.audios, playlistIndex, playlist.title, false, startTime);
        } else {
            window.sr.player.play(audio, startTime);
        }
    }

    private createPlaylist(): Playlist | null {
        const queueString = document.getElementById('audio-playlist')?.getAttribute('data-playlist');

        if (!queueString) {
            return null;
        }

        const playlist = JSON.parse(queueString);

        if (!playlist || !playlist.audios) {
            return null;
        }

        return playlist;
    }

    private getQueueindex(playlist: Playlist | null, audioId: number, type: string): number {
        if (!playlist) {
            return -1;
        }

        return playlist.audios.findIndex(audioKey => audioKey.id === audioId && audioKey.type === type);
    }

    private clickHandler(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        this.play();
    }

    private keyHandler(event: KeyboardEvent): void {
        if (event.which === 13 || event.keyCode === 13) {
            event.preventDefault();
            event.stopPropagation();
            this.play();
        }
    }

    public execute(): void {
        if (!this.el || !window.sr || !window.sr.player) {
            return;
        }

        this.el.addEventListener('click', this.clickHandler.bind(this), true);
        this.el.addEventListener('keydown', this.keyHandler.bind(this), true);
    }

    public dispose(): void {
        if (this.el) {
            this.el.removeEventListener('click', this.clickHandler.bind(this), true);
            this.el.removeEventListener('keydown', this.keyHandler.bind(this), true);
        }

        this.el = null;
    }
}

interface Playlist {
    title: string;
    audios: AudioKey[];
}
