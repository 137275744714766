import { AudioKey } from '@/components/models/AudioKey';
import { MetadataActions, MetadataGetters } from '@/store';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MetadataMixin extends Vue {
    protected get isListenLaterList(): boolean {
        return this.$store.getters[MetadataGetters.IsListenLaterList];
    }

    protected rearrange(items: AudioKey[]): void {
        return this.$store.dispatch(MetadataActions.RearrangeQueue, items);
    }

    $store: any;
}
