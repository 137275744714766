






    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component
    export default class UiSubHeader extends Vue {
        @Prop(Boolean) readonly strong: boolean | undefined;
    }
